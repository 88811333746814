/*------------------------------------------------------------------
[Navs]
-------------------------------------------------------------------*/


/* navbar mobile */
.navbar-cd{
    display: none;
    background-color: #131212;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
    padding: 10px 10px;
}

.navbar-cd .navbar-logo{
    height: 30px;
    width: auto;
    float: left;
}

.navbar-page-toggle{
    float: right;
    padding-right: 5px;
    padding-top: 5px;
}

.navbar-page-toggle .hamburger{
    outline: none!important;
}

.nav-tabs{
    border-bottom: 0px;
    margin-bottom: 0;
}


/* nav style 1 */
ul.nav-style-1{
    margin-bottom: 0;
    li{
        display: inline-block;
    }

    li a{
        display: block;
        padding: 25px 30px;
        font-size: 1.5rem;
    	line-height: 1.5rem;
    	font-family: 'Roboto Condensed', sans-serif;
    	font-weight: 300;
        position: relative;

    }

    a.active:before{
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 15px;
        background-color: #c59014;
    }

    a.active:after{
        content: "\e900";
        @include icomoon;
        font-size: 6px;
        position: absolute;
        left: 50%;
        margin-left: -4px;
        bottom: 4px;
    }
}

@include media-breakpoint-down(md) {
    .navbar-cd{
        display: block;
    }

    ul.nav-style-1 li a{
        font-size: 1.25rem;
        line-height: 1.25rem;
    }
}

@include media-breakpoint-down(sm) {


    ul.nav-style-1{
        li{
            display: block;
        }

        li a{
            text-align: center;
        }
    }
}
