/*------------------------------------------------------------------
[Contact]
-------------------------------------------------------------------*/

.contact-data, .account-data{

    h5{
        margin-bottom: 25px;
        font-weight: 700;
    }
    p{
        margin-bottom: 0;
    }
}

.contact-data-group{
    margin-bottom: 30px;
}

.contact-page-form{
    margin: 50px 0px;

    h3{
        font-weight: 700;
        text-transform: uppercase;
    }
}
