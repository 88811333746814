/*------------------------------------------------------------------
[breadcrumb]
-------------------------------------------------------------------*/

.breadcrumb{
	padding-left: 0;
	padding-top: 0;

	.breadcrumb-item a{
		color:#7f7f7f;
		font-weight: 300;
	}

	.breadcrumb-item a:hover{
		color:#3c8e82;
	}

	.breadcrumb-item + .breadcrumb-item::before{
		content: none;
	}

	.breadcrumb-item.active {
		color:#0a0a0a;
	}

	.breadcrumb-item+.breadcrumb-item::before {
    	display: inline-block;
    	padding-right: .5rem;
    	padding-left: .5rem;
    	color:#7f7f7f;
    	content: "-";
	}

}
