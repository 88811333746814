/*------------------------------------------------------------------
[Forms]
-------------------------------------------------------------------*/

::-webkit-input-placeholder {
   color: #0a0a0a;
   font-size: 15px;
}

:-moz-placeholder { /* Firefox 18- */
	color: #0a0a0a;
    font-size: 15px;
}

::-moz-placeholder {  /* Firefox 19+ */
	color: #0a0a0a;
    font-size: 15px;
}

:-ms-input-placeholder {
	color: #0a0a0a;
    font-size: 15px;
}

.form-group {
    margin-bottom: 10px;
}

.fluid-bg .form-group{
    margin-bottom: 0;
}

.select-group .form-group{
    display: inline-block;
    margin-right: 20px;
}

.form-group label{
    margin-right: 10px;
    margin-bottom: 5px;
}

.row-small{
    margin-left: -5px;
    margin-right: -5px;
}

.row-small [class*="col-"]{
    padding-left: 5px;
    padding-right: 5px;
}



.form-control {
    display: block;
    width: 100%;
    padding: 0.7rem 0.75rem;
    font-size: 1rem;
    line-height: 1.25;
    color: #55595c;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid #cccccc;
    border-radius: 0px;
}

.form-control:focus {
    color: #55595c;
    background-color: #fff;
    border-color: #43938b;
    outline: none;
}

.product-form{
    position: relative;

    .product-form-close{
        position: absolute;
        right: 0;
        top: -35px;
        font-size: 20px;
    }
}

.form-group-icon{
    position: relative;

    input{
        padding-right: 50px;
    }
    .input-ic{
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 45px;

        [class*="icon-"]{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color:#a3a3a3;
            font-size: 12px;
        }
    }
}

.custom-radio{
    border-bottom: 1px solid #ccc;
    margin-bottom: 8px;
    margin-top: 8px;
    padding-bottom: 8px;
    input{
        display: none;
    }

    .label-body{
        display: table-cell;
        vertical-align: middle;
    }

    label{
        position: relative;
        padding-left: 80px;
        height: 60px;
        cursor:pointer;
        display: table;
        vertical-align: middle;
        margin-bottom: 0;

        &:before{
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 50px;
            height: 50px;
            border:1px solid #ccc;
        }

        &:after{
            content: "";
            position: absolute;
            left: 19px;
            top: 18px;
            width: 15px;
            height: 15px;
            background-image: url(../img/label.png);
            background-repeat: no-repeat;
            background-position: top;
        }
    }
}


.custom-radio input:checked + label:after{
    background-position: bottom;
}

.custom-radio-small{
    padding-left: 25px;
    padding-right: 25px;
    label{
        padding-left: 50px;
        height: 30px;

        &:before{
            width: 30px;
            height: 30px;
            border:0px;
        }

        &:after{
            left: 8px;
            top: 8px;
        }
    }

    p{
        margin-bottom: 0;
    }
}

.active-radio-select.active{
    background-color: #f0f0f0;
}

.active-radio-select .custom-radio{
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 8px;
}





@include media-breakpoint-down(md) {
    .select-group .form-group{
        margin-bottom: 15px;
        display: block;
        margin-right: 5px;
    }

    .select-group label{
        display: block;
    }
}
