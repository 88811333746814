/*------------------------------------------------------------------
[Sidebar]
-------------------------------------------------------------------*/

/*sidebar scrollbar */
.mCSB_inside > .mCSB_container{ margin-right: 12px!important; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
	background-color: #fff; background-color: rgba(255,255,255,0.25);
	filter: "alpha(opacity=75)"; -ms-filter: "alpha(opacity=25)";
}

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{
    background-color: #fff; background-color: rgba(255,255,255,0.35);
	filter: "alpha(opacity=75)"; -ms-filter: "alpha(opacity=35)";
}

.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar{
    background-color: #fff; background-color: rgba(255,255,255,0.35);
    filter: "alpha(opacity=90)"; -ms-filter: "alpha(opacity=35)";
}

#sidebar{
    position: fixed;
    left: 0;
    top: 0;
    width: 360px;
    height: 100%;
    max-width: 90%;
    background-color: #212222;
	background-color: rgba(32, 33, 33, 0.95);
	overflow-x: hidden;
	z-index: 9999;
}

.sidebar-scrollbar{
    overflow: hidden;
    height: 100%;
}

.sidebar-inner{
    padding: 30px 35px;
}

.sidebar-logo{
    margin: 80px 0px 40px 0px;
    padding-left: 20px;
}

/* sidebar language */

.sidebar-language{
	padding-left: 20px;
	ul{
		font-size: 0px;
	}
	ul > li{
		display: inline-block;
	}

	ul > li > a{
		border: 2px solid #302c2c;
		background-color: transparent;
		display: block;
		padding: 2px 16px;
		color:#fff;
		text-transform: uppercase;
		font-size: 12px;
		transition: all 0.2s;
	}

	ul> li.active > a{
		border: 2px solid #43938b;
		background-color: #43938b;
	}

	ul > li:not(.active) > a:hover{
		background-color: #000;
		border: 2px solid #000;
	}
}

/* sidebar icons */
.sidebar-search{

	::-webkit-input-placeholder {
	   color: #6f6f6f;
	   font-size: 13px;
	}

	:-moz-placeholder { /* Firefox 18- */
		color: #6f6f6f;
	    font-size: 13px;
	}

	::-moz-placeholder {  /* Firefox 19+ */
		color: #6f6f6f;
	    font-size: 15px;
	}

	:-ms-input-placeholder {
		color: #6f6f6f;
	    font-size: 13px;
	}

	form{
		margin-bottom: 0;
		border-bottom: 1px solid #2e5852;
		position: relative;
		margin-bottom: 5px;

		.form-group{
			margin-bottom: 0px;
		}

		input[type="search"], input[type="text"]{
			background-color: transparent;
			border:0px;
			text-transform: uppercase;
			padding-top: 14px;
			padding-bottom: 14px;
			padding-left: 20px;
			padding-right: 40px;
			font-size: 13px;
		}

		button[type="submit"]{
			position: absolute;
			right: 10px;
			top: 50%;
			transform: translateY(-50%);
			background-color: transparent;
			border:0px;
			outline: 0px;
			color:#fff;
		}
	}

}
/* sidebar menu */
.sidebra-menu ul > li{
    padding: 8px 0px;
}


.sidebra-menu ul > li.divider{
    border-bottom: 1px solid #2f5852;
	margin-bottom: 15px;
	padding-bottom: 20px;
}

.sidebra-menu ul > li.divider.divider-small{
	padding-bottom: 10px;
}

.sidebra-menu > ul > li > a{
	display: block;
    font-weight: 400;
    color:#fff;
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    padding-left: 20px;
    transition: color 0.2s;
}

.sidebra-menu > ul > li.has-dropdown > a{
    color:#2e5852;
    position: relative;
	padding-right: 20px;
}


.sidebra-menu > ul > li.has-dropdown  span.dropdown-icon{
	color:#2e5852;
	font-size: 8px;
	position: relative;
	top:8px;
	position: absolute;
	right: 8px;
	transform: rotate(90deg);
}

.sidebra-menu > ul > li.has-dropdown a.collapsed span.dropdown-icon{
	color:#fff;
	transform: rotate(0deg);
}

.sidebra-menu > ul > li.has-dropdown > a.collapsed{
    color:#fff;
}

.sidebra-menu > ul > li > a:hover, .sidebra-menu > ul > li.has-dropdown > a:hover, .sidebra-menu > ul > li > a:hover .dropdown-icon{
    color:#2e5852;
}

.sidebra-menu .collapse-inner ul > li{
    padding: 0px;
}

.sidebra-menu .collapse-inner{
    padding: 15px 0px 15px 20px;
	border-bottom: 1px solid #2e5852;
}

.sidebra-menu .collapse-inner ul > li > a{
    color:#e1e3e4;
    font-size: 13px;
    transition: color 0.2s;
}

.sidebra-menu .collapse-inner ul > li.active a{
	color:#3c8e82;
	position: relative;
	&:after{
		content: "";
		position: absolute;
		left: -10px;
		top: 0;
		bottom: 0;
		width: 4px;
		background-color: #3c8e82;
	}
}

.sidebra-menu .collapse-inner ul > li > a:hover{
    color:#3c8e82;;
}


@include media-breakpoint-down(md) {
    #sidebar{
        left: -100%;
		transition: all 0.4s;
		z-index: 9999;
		//width: 260px;
		background-color: rgba(32, 33, 33, 1);
		top: 50px;
		width: 100%;
		max-width: 100%;

		.sidebar-language{
			margin: 25px 0;
		}

		.sidebar-logo{
			display: none;
		}
    }

	.sidebar-inner {
    	padding: 30px 5px;
	}

	#sidebar.is-active{
		left: 0;
	}
}
