/*------------------------------------------------------------------
[General Styles]
-------------------------------------------------------------------*/

/* wraper, columns, gutters, containers content etc */
#wrapper{
    //overflow: hidden;
    background-color: #fff;
    padding-left: 360px;
}

#wrapper.wrapper-home-page{
    padding-left: 0;
}

.wrapper-home-page-section{
    padding-left: 360px;
}

#footer{
    padding-left: 360px;
}

.content-inner, .row [class*="col-"]{
    padding-left: 50px;
    padding-right: 50px;
}

img{
    max-width: 100%;
    height: auto;
}

.no-gutter > [class*='col-'] {
  padding-right:0;
  padding-left:0;
}

.no-gutter .no-gutter > [class*='col-'] {
  padding-right:0!important;
  padding-left:0!important;
}

.no-gutter-left > [class*='col-']{
    padding-left: 0;
}
.no-gutter-right{
    padding-right: 0;
}

.fluid-bg{
    padding-left: 50px;
    padding-right: 50px;
    min-height: 70px;
    line-height: 70px;
}

.parallax-bg {
    background-size: cover;
    background-position: 50%;
    background-attachment: fixed;
    //padding-bottom: 30%;
}

.vertical-space-node .content-inner{
    padding-top: 50px;
    padding-bottom: 50px;
}

.row-border [class*="col"]{
    border-bottom: 1px solid #c0c0c0;
    border-right: 1px solid #c0c0c0;
}

.row-border-top [class*="col"]{
    border-top: 1px solid #c0c0c0;
}

.row-border .col-xl-4:nth-child(3n){
    border-right: 0px
}

.row-border-bottom-none [class*="col"]{
    border-bottom: 0px;
}

table{
    width: 100%;
}

.image-block{
    display: block;
    width: 100%;
}

.m-10{
    margin-top: 10px;
    margin-bottom: 10px;
}

.page-content{
    padding-bottom: 60px;
}

.page-content-padding-0{
    padding: 0;
}

.border-list{
    padding: 2px 0;
}

.border-list > li{
    border: 1px solid #3c8e82;
    border-bottom: 0px;
    //border-left: 0px;
    margin-top:-1px;
    margin-left:-1px;
    overflow: hidden;
    position: relative;
    z-index: 11;

}




/* slider */
.slider-wraper{
    position: relative;

    .slider-pager{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 20px;
        z-index: 99;
        text-align: center;

        a{
            display: inline-block;
            border:1px solid #a5a6a5;
        }

        a + a{
            margin-left: 4px;
        }
    }

    .scroll-down{
        position: absolute;
        z-index: 99;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        color:#fff;
        text-align: center;
        padding-left: 360px;

        a{
            color:#fff;
            transition: all 0.3s;
        }

        a:hover{
            color:#3c8e82;
        }
    }
}

@include media-breakpoint-down(lg) {
    .row-border [class*="col"]{
        border-right: 0px;
    }
}

@include media-breakpoint-down(md) {
    #wrapper{
        padding-top: 60px;
    }

    #wrapper.wrapper-home-page{
        padding-top: 50px;
    }
    #wrapper, #footer{
        padding-left: 0px;
    }

    .container-fluid {
        padding-left: 25px;
        padding-right: 25px;
    }

    .row {
        margin-left: -25px;
        margin-right: -25px;
    }

    .row [class*="col-"]{
        padding-left: 25px;
        padding-right: 25px;
    }

    .content-inner{
        padding-left: 0;
        padding-right: 0;
    }

    .content-inner-bg{
        margin-left: -25px;
        margin-right: -25px;
        padding-left: 25px;
        padding-right: 25px;
    }

    .fluid-bg{
        padding-left: 25px;
        padding-right: 25px;
        height: auto;
        min-height: auto;
        line-height: 16px;
        padding-top: 15px;
        padding-bottom: 15px;

        p{
            margin-bottom: 0;
        }
    }

    .no-gutter > [class*='col-'] {
      padding-right:25px;
      padding-left:25px;
    }

    .no-gutter-left > [class*='col-']{
        padding-left: 25px;
    }
    .no-gutter-right{
        padding-right: 25px;
    }

    .wrapper-home-page-section{
        padding-left: 0px;
    }

    #footer{
        padding-left: 0px;
    }
}

@include media-breakpoint-down(sm) {

    .container-fluid {
        padding-left: 15px;
        padding-right: 15px;
    }
    .row {
        margin-left: -15px;
        margin-right: -15px;
    }

    .row [class*="col-"]{
        padding-left: 15px;
        padding-right: 15px;
    }

    .fluid-bg{
        padding-left: 15px;
        padding-right: 15px;
    }

    .content-inner-bg{
        margin-left: -15px;
        margin-right: -15px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .no-gutter > [class*='col-'] {
      padding-right:15px;
      padding-left:15px;
    }

    .no-gutter-left > [class*='col-']{
        padding-left: 15px;
    }
    .no-gutter-right{
        padding-right: 15px;
    }
}

/* colors */
.bg-gray{
    background-color: #f0f0f0;
}
.bg-dark{
    background-color: #181819;
}

.bg-white{
    background-color: #fff;
}
