/*------------------------------------------------------------------
[Map]
-------------------------------------------------------------------*/
.map-container{
    position: relative;
    padding-bottom: 40%;

    #map-canvas{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}

@include media-breakpoint-down(md){
    .map-container{
        position: relative;
        padding-bottom: 40%;
        min-height: 340px;
    }
}
