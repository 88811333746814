/*------------------------------------------------------------------
[Typography]
-------------------------------------------------------------------*/

body{
	font-family: 'Play', sans-serif;
	color:#0a0a0a;
	font-size: 16px;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
	font-family: 'Play', sans-serif;
	color:#0b0c14;

}

h1, .h1{
	font-size: 3rem;
	line-height: 3rem;
	font-weight: 400;
}

h2, .h2{
	font-size: 2.5rem;
	line-height: 2.5rem;
}

h3, .h3{
	font-size: 1.5rem;
	line-height: 1.5rem;
	margin-bottom: 25px;
}

h4, .h4{
	font-size: 1.125rem;
	line-height: 1.125rem;
}

h5, .h5{
	font-size: 1rem;
	line-height: 1rem;
}

h6, .h6{
	font-size: 0.875rem;
	line-height: 0.875rem;
}

h1, h5{
	text-transform: uppercase;
}

.page-title{
	padding-top: 30px;
}

.page-title h1{
	margin: 2.5rem 0px;
}

.headline-title{
    margin: 0;
    padding: 60px 0;
    font-weight: 700;
}

.headline-floats, .headline{
	border-bottom: 1px solid #ccc;
	margin: 40px 0px;
	padding-bottom: 8px;
}

.headline-floats,  .headline{
	color:#bd8746;
	h3{
		margin: 0;
		//padding: 10px 0px;
		color:#bd8746;

	}
}

.headline-floats{
	padding-bottom: 0;
}

.headline-floats h3{
	padding: 10px 0px;
}

.headline-floats:after{
	content: "";
	display: table;
	clear: both;
}


a, a:hover, a:focus, a:active {
    text-decoration: none;
    outline: none;
	color:#1f1d1d;
}

a.link-back{
	position: relative;
	padding-left: 15px;
	display: inline-block;
	&:after{
		content: "\e901";
		@include icomoon;
		color:#c59014;
		font-size: 10px;
		position: absolute;
		left: 0;
		top: 7px;
	}
}

a.link-back:hover{
	color:#c59014;
}

p{
	font-weight: 300;
}

p,
ul,
ol,
pre,
table,
blockquote {
  margin-bottom: 1.625em;
}
ul,
ol {
  list-style: none;
}

ol, ul{
	padding-left: 0;
}

blockquote {
  font-size: 2em;
  line-height: 1.421875em;
  padding: 0;
  margin: 0;
  border-left: 0;
}
strong {
  font-weight: 600;
}
hr {
  margin: 1.625em 0;
  border-color: #f4f4f4;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}

.text-alert{
	color:#d4362a;
}

/* custom cunter list */
ol.custom-counter {
    counter-reset: counter-parent;
	list-style: none;
	padding-left: 0;
}

ol.custom-counter li{
	position: relative;
	padding-left: 25px;
}

ol.custom-counter > li{
	margin: 35px 0px 70px 0px;

	h3{
		margin-bottom: 35px;
		color:#0a0a0a;
	}
}

ol.custom-counter > li > ul {
	margin-bottom: 15px;
}

ol.custom-counter > li > ul > li{
	padding-left: 10px;
}

ol.custom-counter > li > ul > li:after{
	content: "-";
	position: absolute;
	left: 0;
	top: 0;
}

ol.custom-counter > li > ul > li > p{
	margin-bottom: 0;
}

ol.custom-counter > li:before{
	position: absolute;
    left: 0;
    top: 0px;
    content: counter(counter-parent) ". ";
    counter-increment: counter-parent;
	font-size: 1.5rem;
    line-height: 1.5rem;
	color:#0a0a0a;
}

ol.custom-counter > li > ol{
	counter-reset: counter-child;
}

ol.custom-counter > li > ol > li:before{
	position: absolute;
    left: -13px;
    top: 0;
	font-weight: 400;
    content: ". " counter(counter-child);
    counter-increment: counter-child;
}

ol.custom-counter > li > ol > li:after{
	position: absolute;
    left: -22px;
    top: 0;
	font-weight: 400;
    content: counter(counter-parent);
}

ol.custom-counter > li > ol > li{
	margin-bottom: 15px;
	padding-left: 45px;
}

ol.custom-counter > li > ol > li p{
	margin-bottom: 0;
}

ol.custom-counter > li > ol > li > ol{
	counter-reset: list;
}

ol.custom-counter > li > ol > li > ol > li{
	padding-left: 20px;
}

ol.custom-counter > li > ol > li > ol > li p{
	margin-bottom: 0;
}

ol.custom-counter > li > ol > li > ol > li:before{
	position: absolute;
    left: 0px;
	top: 0;
	counter-increment: list;
	font-size: 14px;
    content: counter(list, lower-alpha) ") ";
}

.list-decimal{
	list-style: decimal;
}

@include media-breakpoint-down(md) {

	h1{
		font-size: 2rem;
		line-height: 2rem;
	}

	.page-title {
    	padding-top: 0px;
	}
	.page-title h1 {
    	margin: 1rem 0px;
	}

	.headline-title {
    	padding: 25px 0;
    }
}

@include media-breakpoint-down(sm) {
	h1{
		font-size: 1.75rem;
		line-height: 1.75rem;
	}

	ol.custom-counter > li > ol > li {
    	margin-bottom: 30px;
    	padding-left: 20px;
	}
}
