/*------------------------------------------------------------------
[Figure]
-------------------------------------------------------------------*/

figure{
    position:relative;
    overflow: hidden;
    margin-bottom: 0;

    img{
        display: block;
        width: 100%;
        height: auto;
    }

    figcaption{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transition: all 0.4s;

    }
}

.figure-gallery{
    figcaption{
        background-color: rgba(19, 18, 18, 0);

        span.icon-search{
            color:#fff;
            border:1px solid #fff;
            display: inline-block;
            padding: 12px 14px;
            transform: scale(0.5);
            opacity: 0;
            transition: all 0.4s;
        }
    }
}

.figure-gallery:hover{
    figcaption{
        background-color: rgba(19, 18, 18, 0.6);

        span.icon-search{
            transform: scale(1);
            opacity: 1;
        }
    }
}

.figure-gallery span.icon-search:hover{
    color:#c59014;
    border:1px solid #c59014;
}

.figure-zoom{
    img{
        transition: all 0.4s;
        transform: scale(1);
    }
}

.figure-zoom:hover{
    img{
        transform: scale(1.0);
    }
}


.container-products{

    overflow: hidden;
    .block-link{
        display: block;

    }
}



.figure-product{
    img{
        //transform: scale(1.0);
        //transition: all 1.2s;


    }

    .prod-image{
        position: relative;
        padding-bottom: 58px;
        overflow: hidden;
        clip-path: content-box;



    }

    .prod-image:after{
        content: "";
        position: absolute;
        left: -2px;
        right: -2px;
        top: -2px;
        bottom: -2px;
        background-color: rgba(60, 142, 130, 0);
        transition: all 0.3s;

    }

    .prod-title-wrapper{
        display: table;
        height: 100%;
        width: 100%;
    }

    .prod-title{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        transition: all 0.3s;
        background-color: rgba(0, 0, 0, 0.8);
        transition: all 0.3s;
        //display: table;
        border:0px;
        width: 100%;


        h4{
            text-transform: uppercase;
            position: relative;
            margin: 0;
            padding: 0;
            padding: 20px 0;
            padding-left: 77px;
            padding-right: 10px;
            color:#fff;
            transition: all 0.3s;
            display: table-cell;
            vertical-align: middle;
            z-index: 99;


        }

        .prod-number{
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: 11;

            h4{
                padding-left: 17px;

                &:after{
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 53px;
                    width: 4px;
                    top: 8px;
                    bottom: 8px;

                    background-color: #3c8e82;

                    transition: all 0.3s;

                }
            }
        }
    }
}

.figure-product:hover{

    img{
        //transform: scale(1.1);
    }

    .prod-image:after{
        background-color: rgba(60, 142, 130, 0.47);
    }

    .prod-title{
        background-color: #3c8e82;

        h4:after{
            background-color: #fff;
        }
    }
}

.section-home-offer .figure-product{
    .prod-title h4:after{
        left: 20px;
    }

    .prod-title h4{
        padding-left: 40px;
    }
}




.figure-static{
    figcaption{
        background-color: rgba(19, 18, 18, 0.35);

        h3, h4{
            color:#fff;
        }


        h3{
            margin-top: 16px;
        }
    }
}

.figure-static:hover{
    figcaption{
        background-color: rgba(19, 18, 18, 0.65);
    }
}

.figure-main-slider{
    //background-size: cover;
    overflow: hidden;
    //background-position: 50%;
    //background-size: 110%;

    img{
        width: 110%;
        height: 110%;
        opacity: 0;
        visibility: hidden;
    }


    figcaption{
        background-color: rgba(19, 18, 18, 0.35);
        padding-left: 360px;

        h3, h1{
            color:#fff;
        }


        h3{
            margin-top: 16px;
            position: relative;
            padding: 12px 0px;
            padding-left: 20px;

            &:before{
                content:"";
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                width: 4px;
                background-color: #3c8e82;
            }
        }

    }

    .slider-inner-text{
        margin-left: 20%;
        padding-right: 55px;
        max-width: 600px;
        opacity: 0;
        transform: translateX(-50px);
        transition: all 0.5s 0.3s;
    }
}

.active-slide .figure-main-slider{

    .slider-inner-text{

        opacity: 1;
        transform: translateX(0px);
    }
}


.div-parallax {

    background-size: cover;
    position: absolute;
    width: 110%;
    height: 110%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.intro-parallax {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    li{
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
}

#slider-home-pager{
    position: absolute;
    right: 55px;
    top: 45px;
    z-index: 99;

    img{
        border:2px solid transparent;
    }

    a.active img{
        border:2px solid #43938b;
    }
}

.pager-style-1{
    a{
        position: relative;
        display: inline-block;
    }

    a.active img{
        border:2px solid #43938b;
    }
}

.parallax-section{
    background-size: cover;
    background-position: 50%;
    background-attachment: fixed;


    figure{
        padding-bottom: 43%;
    }

}

.parallax-bg{
    h1, h2, h3, p{
        color:#fff;
    }

    img{
        opacity: 0;
    }

    p{
        color:#fff;
        max-width: 940px;
    }

    h1{
        margin-bottom: 30px;
    }
}

@media (max-width: 1600px){
    .section-new-products .slider-controls{
        position: static;
        transform: translateX(0%);
        text-align: center;
        padding: 20px 0px;
        padding-right: 0;
    }

    .section-new-products .slider-controls span{
        float: none;
    }
}


@include media-breakpoint-up(lg){
    .main-slider, .main-slider .slider-wraper{
        height: 100vh;
    }

    .main-slider .bx-wrapper, .main-slider .bx-viewport, .main-slider .bx-wrapper ul li, .main-slider .slider-home, .main-slider .slider-home > li, .main-slider .slider-home > li >figure {
        height: 100%!important;
    }
}

@include media-breakpoint-down(lg) {
    .figure-main-slider figcaption h1 {
        font-size: 1.5rem;
    }


    .figure-main-slider figcaption h3{
        margin-top: 16px;
        position: relative;
        padding: 6px 0px;
        padding-left: 20px;
    }

    .slider-inner-text{
        padding-bottom: 60px;
        margin-left: 15%;
    }

    #slider-home-pager{
        right: 10px;
        bottom: 10px;
    }

    .figure-main-slider .slider-inner-text{
        right: 10px;
        bottom: 10px;
        margin-left: 10%;
    }
}

@include media-breakpoint-down(md) {
    .figure-product figcaption{
        position: static;
        background-color: #131212;
    }

    .figure-product figcaption .item-grid--hover{
        opacity: 1;
        padding: 20px 10px;
    }

    .figure-main-slider .slider-inner-text{
        margin-left: 0;
        padding: 20px 20px;
    }

    #slider-home-pager{
        position: static;
        padding: 40px;
    }

    .parallax-section{
        margin-top: 40px;
    }

    .parallax-section figure{
        padding-bottom: 0;

        figcaption{
            position: static;
            padding: 80px 0px;
        }
    }

    .section-new-products {
        padding-bottom: 65px;
    }


    #slider-home-pager{
        display: none;
    }

    .figure-main-slider figcaption, .figure-static figcaption{
        position: static;
        background-color: rgba(19, 18, 18, 0.95);
    }

    .figure-main-slider img{
        opacity: 1;
        visibility: visible;
    }

    .intro-parallax{
        display: none;
    }

    .figure-static .item-grid--hover{
        padding: 20px 10px;
    }

    .figure-main-slider .slider-inner-text{
        margin-left: 0%!important;
    }

    .figure-main-slider figcaption{
        padding-left: 0;
    }

    .slider-wraper .scroll-down{
        display: none;
    }

}

@include media-breakpoint-down(sm) {

}
