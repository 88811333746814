/*

Project:	   Lampart
Version:	   1.0
Assigned to:   Comup.pl

===================================================================================
Table of Contents
===================================================================================

*/

//boostrap variables & mixins
@import "../bootstrap-4.0.0-alpha.4/_variables.scss";
@import "../bootstrap-4.0.0-alpha.4/mixins/_breakpoints.scss";


//helper
@import "helpers/_helper-classes.scss";
@import "helpers/_mixins.scss";



//base
@import "base/_typography.scss";
@import "base/_general.scss";
@import "base/_icomoon.scss";



//vendor
@import "vendor/jquery.mCustomScrollbar.scss";
@import "vendor/hamburgers/hamburgers.scss";
@import "vendor/animate.scss";

//component
@import "components/_buttons.scss";
@import "components/_form.scss";
@import "components/_map.scss";
@import "components/_breadcrumb.scss";
@import "components/_nav.scss";
@import "components/_figure.scss";

//layout
@import "layout/_sidebar.scss";
@import "layout/_footer.scss";
@import "layout/_customer-grid.scss";
@import "layout/_products.scss";
@import "layout/_contact.scss";
