/*------------------------------------------------------------------
[Icomoon]
-------------------------------------------------------------------*/

@font-face {
    font-family: 'icomoon';
    src:    url('../fonts/icomoon/icomoon.eot?60fiv9');
    src:    url('../fonts/icomoon/icomoon.eot?60fiv9#iefix') format('embedded-opentype'),
        url('../fonts/icomoon/icomoon.ttf?60fiv9') format('truetype'),
        url('../fonts/icomoon/icomoon.woff?60fiv9') format('woff'),
        url('../fonts/icomoon/icomoon.svg?60fiv9#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}


[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-download3:before {
  content: "\e9c7";
}
.icon-upload3:before {
  content: "\e9c8";
}
.icon-attachment:before {
  content: "\e9cd";
}
.icon-cross:before {
  content: "\ea0f";
}
.icon-google-plus2:before {
  content: "\ea8c";
}
.icon-google-plus3:before {
  content: "\ea8d";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-facebook2:before {
  content: "\ea91";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-youtube:before {
  content: "\ea9d";
}
.icon-youtube2:before {
  content: "\ea9e";
}
.icon-vimeo:before {
  content: "\eaa0";
}
.icon-vimeo2:before {
  content: "\eaa1";
}
.icon-linkedin:before {
  content: "\eac9";
}
.icon-linkedin2:before {
  content: "\eaca";
}
.icon-pinterest2:before {
  content: "\ead2";
}
.icon-file-pdf:before {
  content: "\eadf";
}
.icon-file-openoffice:before {
  content: "\eae0";
}
.icon-file-word:before {
  content: "\eae1";
}
.icon-file-excel:before {
  content: "\eae2";
}
.icon-arrow-down:before {
  content: "\e900";
}
.icon-arrow-left:before {
  content: "\e901";
}
.icon-arrow-right:before {
  content: "\e902";
}
.icon-arrow-up:before {
  content: "\e903";
}
.icon-basket:before {
  content: "\e904";
}
.icon-facebook3:before {
  content: "\e905";
}
.icon-file-pdf2:before {
  content: "\e906";
}
.icon-guns:before {
  content: "\e907";
}
.icon-lock:before {
  content: "\e908";
}
.icon-remove:before {
  content: "\e909";
}
.icon-search:before {
  content: "\e90a";
}
