/*------------------------------------------------------------------
[Customers grid]
-------------------------------------------------------------------*/

.customers-grid .item-grid {}

.customers-grid .item-grid{
    display: table;
    height: 100%;
    width: 100%;
    padding: 60px 20px;
}

.customers-grid .item-grid--logo{
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding: 0 25px;
}

.customers-grid .item-grid--logo p{
    margin-top: 25px;
    text-align: center;
    font-size: 20px;
    margin-bottom: 0;
}

.customers-grid a{
    display: inline-block;
}

.customers-grid a img{
    transform: scale(1.0);
    transition: all 0.4s;
}

.customers-grid a p{
    transition: all 0.4s;
}

.customers-grid a:hover img{
    transform: scale(1.1);
}

.customers-grid a:hover p{
    color:#3c8e82;
}
