/*------------------------------------------------------------------
[Products]
-------------------------------------------------------------------*/

/* product single */
.product-single{
    h4{
        margin-bottom: 30px;
    }

    .content-inner{
        padding-top: 45px;
    }

    .form-group {
        margin-bottom: 15px;
    }

    textarea{
        min-height: 120px;
    }
}

.product-single .product-image{
    border: 1px solid #3c8e82;

    img{
        width: 100%;
        display: block;
        height: auto;
    }
}

.product-info-block{
    border-top:1px solid #43938b;
    border-bottom:1px solid #43938b;
    padding: 35px 0;
}

.product-block{
    margin: 35px 0;
}
.product-file{

    p{
        margin-bottom: 0;
    }

    a{
        margin-left: 10px;
    }

    a:hover{
        color:#43938b;
    }

    [class*="icon"]{
        font-size: 22px;
        margin-right: 10px;
        position: relative;
        top: 5px;
    }
}

.product-availability{
    padding: 35px 0;
}

.product-description{
    ul{
        margin-top: 25px;
    }

    ul li p{
        margin-bottom: 0;
    }
    table{
        table-layout: auto;
    }

    table tr td{
        white-space:nowrap
    }

    table td:first-child{
        padding-right: 25px;
    }

    table td:last-child{
        width:100%;
    }


}
.section-new-products{
    padding-top: 100px;
    padding-bottom: 60px;

    .slide-img{
        padding: 5px 0;
    }

    img{
        border:1px solid #3c8e82;
    }
}

.slider-new-prod-item{
    text-align: center;
}

.slider-new-prod-item-inner{
    border:1px solid #3c8e82;
    width: 100%;
    max-width: 400px;
    text-align: left;
    display: inline-block;

    h4{
        position: relative;
        margin-bottom: 5px;
        color:#3c8e82;
    }

    p{
        margin-bottom: 0;
    }

    .slide-inner{
        padding: 40px 30px;
        padding-left: 60px;
        position: relative;

        &:after{
            content: "";
            position: absolute;
            left: 30px;
            width: 4px;
            top: 25px;
            bottom: 25px;
            background-color: #3c8e82;
            transition: all 0.3s;
        }
    }
}

.section-new-products{
    .headlines-group{
        margin-bottom: 30px;
    }
    .slider-controls{
        position: absolute;
        padding-right: 0px;
        bottom: 25px;
        left: 50%;
        transform: translateX(-50%);

        span{
            float: left;
        }

        [class^="icon-"]{
            font-size: 10px;
            padding: 18px 35px;
            display: inline-block;
            color:#3c8e82;
            background-color: #131212;
            transition: all 0.3s;
        }

        [class^="icon-"]:hover{
            background-color: #3c8e82;
            color:#fff;
        }
    }
}

@include media-breakpoint-down(lg){
    .parallax-section{
        background-attachment: scroll;
    }
}

@include media-breakpoint-down(md){
    .product-single{
        padding-top: 25px;
    }

    .product-add .qnt-holder{
        margin-bottom: 20px;
    }

    .slider-new-prod-item-inner{
        display: block;
        width: 100%;
        max-width: 100%;
    }

    .section-new-products{
        padding-top: 40px;
        padding-bottom: 40px;
    }


}
