/*------------------------------------------------------------------
[Buttons]
-------------------------------------------------------------------*/

.btn{
    text-transform: uppercase;
    border:0px;
    font-size: 14px;
    font-weight: 300;
    padding: 0.95rem 1.6rem;
    transition: all 0.3s;
}

.btn-primary, .btn-primary:focus, .btn-primary::active{
    background-color: #131212;
    color:#fff;
    outline: 0px;
}

.btn-primary:hover{
    background-color: #3c8e82;
}

.btn-secondary, .btn-secondary:focus, .btn-secondary:active{
    background-color: #43938b;
    color:#fff;
    outline: 0px;
}

.btn-secondary:hover{
    background-color: #131212;
    color:#fff;
}

.btn [class*="icon-"]{
    margin-right: 8px;
}
