/*------------------------------------------------------------------
[Helper Classes]
-------------------------------------------------------------------*/

.clear{
    clear: both;
}

/**! Element boostrap **/
@media only screen and (min-width : 768px) {
    .is-table-row {
        display: table;
        width: 100%;
    }

    .is-table-row [class*="col-"] {
        float: none;
        display: table-cell;
        vertical-align: top;
        height: 100%;
    }

    .row.match-my-cols {
        overflow: hidden;
    }

    .row.match-my-cols [class*="col-"]{
      margin-bottom: -99999px;
      padding-bottom: 99999px;
    }


}

/**! Element align **/
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

@media all and (max-width: 1199px) {
  .text-center-md {
    text-align: center;
  }
  .text-left-md {
    text-align: left;
  }
  .text-right-md {
    text-align: right;
  }
}
@media all and (max-width: 991px) {
  .text-center-sm {
    text-align: center;
  }
  .text-left-sm {
    text-align: left;
  }
  .text-right-sm {
    text-align: right;
  }
}
@media all and (max-width: 767px) {
  .text-center-xs {
    text-align: center;
  }
  .text-left-xs {
    text-align: left;
  }
  .text-right-xs {
    text-align: right;
  }
}


/**! General helper classes **/
.clearfix {
  overflow: hidden;
}
.allow-overflow {
  overflow: visible;
}
.pos-relative {
  position: relative;
}
.pos-absolute {
  position: absolute;
}
.inline-block {
  display: inline-block;
}
.block {
  display: block;
}
.table{
    display: table;
    margin-bottom: 0;
}

.inline-center{
    text-align: center;
}

.full-height, .full-h{
    height: 100%;
}

.table-cell{
    display: table-cell;
}

.h-top{
    vertical-align: top;
}

.h-center{
    vertical-align: middle;
}
.h-bottom{
    vertical-align: bottom;
}
