.footer{
    .row{
        background-color: #131212;
    }
    font-size: 14px;

    .footer-bottom{
        padding-top: 40px;
        padding-bottom: 40px;
        p, a{
            color:rgba(255, 255, 255, 0.4);
        }

        p{
            display: inline-block;
            margin-bottom: 0;
        }

        ul{
            margin-bottom: 0;
        }

        ul >li{
            display: inline-block;
            padding-left: 20px;
        }

        a.comup{
            color:rgba(255, 255, 255, 0.6);
        }

        a:hover{
            color:rgba(255, 255, 255, 0.8);
        }
    }
}

@media (max-width: 1919px){
    .footer .footer-bottom ul > li{
        display: block;
    }
}

@include media-breakpoint-down(lg) {
    .footer .footer-bottom ul > li{
        padding-left: 0;
    }
}

@include media-breakpoint-down(sm) {

}
