@mixin bp-xs {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

@mixin bp-sm {
  @media only screen and (max-width: 991px) {
    @content;
  }
}

@mixin bp-md {
  @media only screen and (max-width: 1199px) {
    @content;
  }
}

@mixin bp-xl {
  @media only screen and (min-width: 1600px) {
    @content;
  }
}


//icomoon font as pseudo element
@mixin icomoon{
	/* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


